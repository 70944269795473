import React from "react"
import { Link, useTranslation } from "gatsby-plugin-react-i18next"
import { TranslationKey } from "types/translation"

type P = {
	nav1Title: TranslationKey
	nav2Title: TranslationKey
	items: TranslationKey[]
}

export const Sidebar = ({ nav1Title, nav2Title, items }: P) => {
	const { t } = useTranslation()

	return (
		<div className="hidden sm:flex flex-col gap-8 sticky top-44 col-span-1">
			<div className="flex flex-col gap-6">
				<Link to="/privacy-policy/" className="text-lg">
					{t(nav1Title) as string}
				</Link>
				<Link
					to={`#${t(nav2Title)}`}
					className="text-lg text-theme-blue border-r-4 border-theme-blue"
				>
					{t(nav2Title) as string}
				</Link>

				<div className="flex flex-col pl-6 gap-4">
					{items.map((item) => {
						return (
							<Link key={item} to={`#${t(item)}`} className='hover:text-b-900 focus:text-b-900'>
								{t(item) as string}
							</Link>
						)
					})}
				</div>
			</div>
		</div>
	)
}
