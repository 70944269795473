import React from "react";
import { graphql } from "gatsby";
import { PageContent } from "components/pages/terms-of-use/wrapper";

const TermsOfUsePage = () => {
  return (
    <PageContent />
  );
};

export default TermsOfUsePage;

// FOR LANGUAGE TRANSLATION
export const query = graphql`
  query ($language: String!) {
    locales: allLocale(filter: {language: {eq: $language}}) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`; 